const swiperWidth = (block) => {
	const el = block;
	const elBlockWidth = el.closest('.b-about-intro').offsetWidth;
	const elWrapperWidth = el.closest('.o-wrapper').offsetWidth;
	const elWidth = el.parentElement.offsetWidth;
	const width = parseFloat((elBlockWidth - elWrapperWidth) / 2) + elWidth + 15;
	el.style.width = `${width}px`;

	return width;
};

const blockAboutIntro = (Swiper, Autoplay) => {
	const block = '.js-swiper-about-intro';
	const blockContainer = document.querySelector(block);
	if (!blockContainer) return false;
	if (window.innerWidth > 768) {
		swiperWidth(blockContainer);
	}
	let swiper = '';

	const swiperParams = {
		modules: [Autoplay],
		pagination: false,
		navigation: false,
		autoplay: true,
		slidesPerView: 1.1,
		spaceBetween: 10,
		disableOnInteraction: false,
		resizeObserver: false,
		loop: true,
		on: {
			beforeResize: () => {
				if (window.innerWidth > 768) {
					swiperWidth(blockContainer);
				} else {
					blockContainer.style.width = 'auto';
				}
			},
		},
		breakpoints: {
			1024: {
				slidesPerView: 1.365,
				spaceBetween: 24,
			},
		},
	};

	swiper = new Swiper(block, swiperParams);

	return swiper;
};

export default blockAboutIntro;
