const blockImageTextSlider = (Swiper, Navigation) => {
	const block = '.js-swiper-its';
	const blockContainer = document.querySelector(block);
	if (!blockContainer) return false;

	let swiper = '';

	const swiperParams = {
		modules: [Navigation],
		pagination: false,
		navigation: {
			nextEl: '.js-its-next',
			prevEl: '.js-its-prev',
		},
		autoplay: true,
		slidesPerView: 1,
		spaceBetween: 15,
		loop: true,
		breakpoints: {
			580: {
				slidesPerView: 2,
				spaceBetween: 24,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 24,
			},
		},
	};

	swiper = new Swiper(block, swiperParams);

	return swiper;
};
export default blockImageTextSlider;
