const hiddenPhone = () => {
	const hiddenPhones = document.querySelectorAll('[data-hidden-phone]');
	hiddenPhones.forEach((el) => {
		const showPhone = el;
		const number = showPhone.dataset.hiddenPhone;
		showPhone.onclick = (e) => {
			e.preventDefault();
			showPhone.parentElement.setAttribute('href', `tel:${number.replace(/\s+/g, '')}`);
			showPhone.parentElement.innerText = number;
		};
	});
};
export default hiddenPhone;
