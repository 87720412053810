const modalOpen = () => {
	const modalBtn = document.querySelectorAll('[data-modal-id]');
	if (!modalBtn) return;

	modalBtn.forEach((el) => {
		const btn = el;
		btn.onclick = (e) => {
			e.preventDefault();
			const { modalId } = btn.dataset;
			const modalContent = document.getElementById(modalId);
			modalContent.classList.toggle('active');
		};
	});
};
export default modalOpen;
