const blockIconTitleText = (Swiper, Navigation) => {
	const block = '.js-swiper-itt';
	const blockContainer = document.querySelector(block);
	if (!blockContainer) return false;

	let swiper = '';

	const swiperParams = {
		modules: [Navigation],
		pagination: false,
		centeredSlides: false,
		navigation: {
			nextEl: '.js-itt-next',
			prevEl: '.js-itt-prev',
		},
		autoplay: true,
		slidesPerView: 1,
		spaceBetween: 0,
		loop: true,
		breakpoints: {
			768: {
				slidesPerView: 3,
				spaceBetween: 24,
			},
		},
	};

	swiper = new Swiper(block, swiperParams);

	return swiper;
};
export default blockIconTitleText;
