import GLightbox from 'glightbox';

const blockGallery = (Swiper, Autoplay) => {
	const block = '.js-swiper-gallery';
	const blockContainer = document.querySelector(block);
	if (!blockContainer) return false;

	let swiper = '';

	const swiperParams = {
		modules: [Autoplay],
		pagination: false,
		navigation: false,
		autoplay: true,
		slidesPerView: 1.06,
		spaceBetween: 10,
		loop: true,
		breakpoints: {
			768: {
				slidesPerView: 4,
				spaceBetween: 24,
			},
		},
	};

	swiper = new Swiper(block, swiperParams);

	GLightbox({
		touchNavigation: true,
	});

	return swiper;
};
export default blockGallery;
