import scrollIntoView from 'smooth-scroll-into-view-if-needed';

const scrollTo = (anchor, mode) => {
	if (anchor) {
		scrollIntoView(anchor, {
			scrollMode: mode,
			block: 'start',
			inline: 'start',
			duration: 1000,
		});
	}
};

export default scrollTo;
