import scrollTo from './scrollto';

const scrollDown = () => {
	const btn = document.getElementById('scroll-down');
	if (btn) {
		btn.onclick = () => {
			scrollTo(btn.closest('section').nextElementSibling);
		};
	}
};

export default scrollDown;
