/*
  Project: Racławicka Park
  Author: WebCrafters Studio
 */

import Swiper, { Autoplay, Navigation } from 'swiper';
import blockAboutIntro from './modules/block-about-intro';
import './modules/main-nav';
import accordionsBlock from './modules/accordion';
import hiddenPhone from './modules/hidden-phone';
import blockGallery from './modules/block-gallery';
import blockIconTitleText from './modules/block-icon-title-text';
import blockImageTextSlider from './modules/block-image-text-slider';
import modalOpen from './modules/modal';
import scrollDown from './modules/block-hero';

document.addEventListener('DOMContentLoaded', () => {
	blockAboutIntro(Swiper, Autoplay);
	blockGallery(Swiper, Autoplay);
	blockIconTitleText(Swiper, Navigation);
	blockImageTextSlider(Swiper, Navigation);
	hiddenPhone();
	accordionsBlock();
	modalOpen();
	scrollDown();
	const header = document.getElementById('js-header');
	if (header) {
		window.onscroll = () => {
			const top = document.querySelector('main').getBoundingClientRect();
			if (top.top < -200) {
				header.classList.add('scrolled');
				return;
			}
			header.classList.remove('scrolled');
		};
	}
});

document.addEventListener('htmlChange', accordionsBlock);
document.addEventListener('htmlChange', modalOpen);
