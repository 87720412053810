// Mobile menu
const navMobileBtn = document.getElementById('js-main-nav-mobile');
if (navMobileBtn) {
	navMobileBtn.onclick = (event) => {
		event.currentTarget.classList.toggle('active');
		event.currentTarget.previousElementSibling.classList.toggle('active');
	};
}

const navDropDown = document.querySelectorAll('a.menu-item-has-children');
if (navDropDown) {
	navDropDown.forEach((dropdown) => {
		const btn = dropdown;
		btn.onclick = (e) => {
			e.preventDefault();
			btn.classList.toggle('active');
			btn.nextElementSibling.classList.toggle('active');
		};
	});
}
